/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Impersonate from './pages/ImpersonateIlpi';
import { DoctorLPProvider } from './context/doctorLPContext';
import PATHS from './constants/applicationPaths';
import EditMarketplaceList from './pages/marketplace/components/EditMarketplaceList';
import ShoppingList from './pages/shoppingList';
import Marketplace from './pages/marketplace';
import Login from './pages/Login';
import DoctorLandingPage from './pages/DoctorLandingPage';
import ShoppingListPreview from './pages/shoppingList/components/preview';
import NotFound from './pages/notFound';
import Order from './pages/Order/Index';
import Evolution from './pages/Evolutions';
import Prescription from './pages/Prescription';
import Recipe from './pages/Recipe';
import CaregiverHome from './pages/CaregiverHome/Index';
import IlpiHome from './pages/IlpiHome/ILPI';
import BenvoHome from './pages/BenvoHome/Index';
import Ilpi from './pages/ilpi';
import Tasks from './pages/Tasks';
import TaskRegistry from './pages/TaskRegistry';
import PageLayout from './components/PageLayout/Index';
import ChangePassword from './pages/ChangePassword';
import SignatureChecker from './pages/SignatureChecker';
import Residents from './pages/residents';
// import ResidentsInformation from './pages/residentsInformation';
// import ResidentData from './pages/residentsInformation/components/ResidentData';
// import ResidentPrescriptions from './pages/residentsInformation/components/ResidentPrescriptions';
// import ResidentRecipes from './pages/residentsInformation/components/ResidentRecipes';
// import ResidentAgenda from './pages/residentsInformation/components/ResidentAgenda';
import Seller from './pages/seller';
import Collaborators from './pages/collaborator';
// import RequiredServices from './pages/healthService';
// import RequestServicePage from './pages/healthService/components/RequestServicePage';
import Terms from './pages/Terms';
import OrderHistory from './pages/orderHistory';
import ResidentShoppingList from './pages/residentShoppingList';
import TermsPage from './pages/termsPage';
import FirstAccess from './pages/firstAccess';
import AuthLayout from './components/AuthLayout';
import RecoverPassword from './pages/recoverPassword';
import SendEmail from './pages/sendEmail';
import RedefinePassword from './pages/redefinePassword';

function Routers() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={PATHS.login} />} />
      <Route path={PATHS.login} element={<Login />} />
      <Route element={<PageLayout />}>
        <Route path={PATHS.home} element={<IlpiHome />} />
        <Route path={PATHS.marketplace} element={<Marketplace />} />
        <Route path={`${PATHS.marketplace}/:id`} element={<EditMarketplaceList />} />
        <Route path={PATHS.orders} element={<Order />} />
        <Route path={PATHS.prescriptions} element={<Prescription />} />
        <Route path={PATHS.evolutions} element={<Evolution />} />
        <Route path={PATHS.shoppingList} element={<ShoppingList />} />
        <Route path={PATHS.residents.root} element={<Residents />} />
        <Route path={`${PATHS.residents.root}/:id/${PATHS.residents.orderHistory}`} element={<OrderHistory />} />
        <Route
          path={`${PATHS.residents.root}/:residentId/${PATHS.residents.shoppingList}/:shoppingListId`}
          element={<ResidentShoppingList />}
        />
        {/* <Route path={`${PATHS.residents.root}/:id`} element={<ResidentsInformation />}>
          <Route path="dados-do-residente" element={<ResidentData />} />
          <Route path="prescricoes" element={<ResidentPrescriptions />} />
          <Route path="receita-de-medicamentos" element={<ResidentRecipes />} />
          <Route path="agenda" element={<ResidentAgenda />} />
        </Route> */}
        <Route path={PATHS.recipes} element={<Recipe />} />
        <Route path={PATHS.collaborators} element={<Collaborators />} />
        <Route path={PATHS.tasks} element={<Tasks />} />
        <Route path={PATHS.tasksRegistry} element={<TaskRegistry />} />
        <Route path={PATHS.changePassword} element={<ChangePassword />} />
      </Route>

      <Route element={<PageLayout />}>
        <Route path={PATHS.caregiver.home} element={<CaregiverHome />} />
      </Route>

      <Route element={<PageLayout />}>
        <Route path={PATHS.superadmin.root} element={<Navigate to={PATHS.superadmin.home} />} />
        <Route path={PATHS.superadmin.home} element={<BenvoHome />} />
        <Route path={PATHS.superadmin.ilpis} element={<Ilpi />} />
        <Route path={PATHS.superadmin.sellers} element={<Seller />} />
        <Route path={PATHS.superadmin.marketplace} element={<Marketplace />} />
      </Route>

      <Route>
        <Route
          path={PATHS.public.doctorLP}
          element={(
            <DoctorLPProvider>
              <DoctorLandingPage />
            </DoctorLPProvider>
                    )}
        />
        <Route path={PATHS.public.shoppingList} element={<ShoppingListPreview />} />
        <Route path={PATHS.public.certificateChecker} element={<SignatureChecker />} />
        <Route path={PATHS.public.privacyPolicy} element={<TermsPage />} />
        <Route path={PATHS.public.termsAndConditions} element={<TermsPage />} />

        <Route element={<AuthLayout />}>
          <Route path={PATHS.auth.firstAccess} element={<FirstAccess />} />
          <Route path={PATHS.auth.recoverPassword} element={<RecoverPassword />} />
          <Route path={PATHS.auth.sendEmail} element={<SendEmail />} />
          <Route path={PATHS.auth.redefinePassword} element={<RedefinePassword />} />
        </Route>
      </Route>

      <Route path={PATHS.terms} element={<Terms />} />
      <Route path={PATHS.impersonate} element={<Impersonate />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Routers;
